import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsAndConditions from './components/TermsAndConditions';
import Header from './components/Header';
// import Footer from './components/Footer';
// import FooterCopyright from './components/FooterCopyright';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
        </Routes>
        {/* <Footer />
        <FooterCopyright /> */}
      </div>
    </Router>
  );
}

export default App;