import { useLocation, Link } from "react-router-dom";
import logo from '../assets/logo.png';

const Header = () => {
  const location = useLocation();

  return (
    <div className="header">
      <div className="header-logo">
        <a href="/"><img src={logo} alt="WeOfferEMD Logo" style={{height: '400px'}}/></a>
      </div>
    </div>
  );
};

export default Header;
