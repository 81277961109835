import React from 'react';
import Waitlist from './Waitlist';

const Home = () => {
  return (
    <>
        <Waitlist />
    </>
  );
}

export default Home;