import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import axios from 'axios';

const WaitlistForm = () => {
  const { register, handleSubmit, setError, setValue, reset, formState: { errors, isSubmitting } } = useForm()
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [featureMessage, setFeatureMessage] = useState(''); // New state variable

  const onSubmit = async (data) => {
    // setFeatureMessage("This feature is coming soon!"); // Set the feature message
  
    try {
     // Add a new property 'date' to the data object
     data.date = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });

      setTimeout(() => {
        axios.post('https://gcbod52cv3.execute-api.us-east-1.amazonaws.com/prod', data)
      }, 1000);
      setIsSubmitted(true); // Set isSubmitted to true when form is submitted
  
      // Set a timeout to set isSubmitted back to false after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
        reset(); // Reset the form
        // setFeatureMessage(''); // Reset the feature message
      }, 3000);
    } catch (error) {
      setError("root", {
        message: "An error occurred, please try again later"
      });
    }
  };

  return (
    <>
        <form onSubmit={ handleSubmit(onSubmit) }>
            <input 
              {...register("full-name", {
                required: "Full name is required",
                validate: (value) => {
                  const nameParts = value.split(' ');
                  if (nameParts.length < 2) {
                    return "Must include both first and last name";
                  }
                  if (nameParts.some(part => part.length < 2)) {
                    return "Both first and last name must be at least 2 characters";
                  }
                    return true;
                }
              })} 
              input="text" 
              placeholder="Full Name" 
              name="full-name" 
              autoComplete="name" 
            />
            {errors['full-name'] && <div style={{ color: "#FF89A9" }}>{errors['full-name'].message}</div>}
            <input 
              {...register("email", {
                required: "Email is required",
                validate: (value) => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!emailRegex.test(value)) {
                        return "Must submit a valid email";
                    }
                    return true;
              }})}
              onChange={(event) => {
                setValue("email", event.target.value.trim());
              }}
              input="text" 
              placeholder="Email Address" 
              name="email" 
              autoComplete="email" 
            />
            {errors.email && <div style={{ color: "#FF89A9" }}>{errors.email.message}</div>}
            <button disabled={isSubmitting} type="submit">{isSubmitting ? "Loading..." : "TAKE CONTROL"}</button>
            {isSubmitted && <div style={{ color: "#FF89A9" }}>Your form is submitted!</div>}
            {/* {featureMessage && <div style={{ color: "#FF89A9" }}>{featureMessage}</div>} */}
            {errors.root && <div style={{ color: "#FF89A9" }}>{errors.root.message}</div>}
        </form>
    </>
  );
};

export default WaitlistForm;
