import WaitlistForm from './WaitlistForm';

const Waitlist = () => {
  return (
    <div className='waitlist-wrapper'>
        <div className='waitlist-section'>
            <h1>Subscribe</h1>
            <h6>Contact support@skinnydfit.com with questions.</h6>
            <WaitlistForm />
        </div>
    </div>
  );
}

export default Waitlist;